import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import AxiosInstance from "../config/AxiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import Toast from "../Toast";
import { Link } from "react-router-dom";

const Editor = (props) => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [loaded, setLoaded] = useState(true);
  const [member, setMember] = useState(null);
  const [searchMembers, setSearchMembers] = useState({
    selectedMembers: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    selectedMember: null,
    selectedMemberId: 0,
  });

  const handleChangeSearchedMember = async (e, v) => {
    let value = null;
    let id = 0;

    setLoaded(false);

    if (v?.id) {
      value = v;
      id = v.id;

      const response = await AxiosInstance.get("/member/details", {
        params: {
          member_id: id,
        },
      });

      setMember(response.data);
    }
    setSelectedSearchMembers("selectedMember", value);
    setSelectedSearchMembers("selectedMemberId", id);

    setLoaded(true);
  };

  const setSelectedSearchMembers = (key, value) => {
    setSearchMembers((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const fetchMembersAll = async () => {
      const response = await AxiosInstance.get("/members/all");
      const data = response.data;

      const selectedMembers = data.map((member) => ({
        id: member.id,
        label: (
          member.lastname +
          " " +
          member.firstname +
          ", " +
          member.middlename[0] +
          ". "
        ).toUpperCase(),
      }));

      setSelectedSearchMembers("selectedMembers", selectedMembers);
    };

    fetchMembersAll();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "gray",
        pt: 5,
        pb: 5,
      }}
    >
      <Container>
        <Paper sx={{ mt: 3, mb: 3 }}>
          <Box
            sx={{ width: "100%", height: "auto", padding: 5 }}
            component="form"
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", color: "primary.main", mb: 5 }}
            >
              ACCREDITED REAL ESTATE SALESPERSONS <br /> OF THE PHILIPPINES INC.
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: "gray" }}>
              {" "}
              Select a member for editing
            </Typography>
            <FormControl fullWidth>
              <Autocomplete
                sx={{ mt: 3 }}
                options={searchMembers.selectedMembers}
                value={searchMembers.selectedMember}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a member..."
                    variant="filled"
                    name="member"
                    type="text"
                  />
                )}
                onChange={(event, value) => {
                  handleChangeSearchedMember(event, value);
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option.id === value.id
                }
              />
            </FormControl>
            {member !== null && loaded && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      width: 200,
                      height: 200,
                      textAlign: "center",
                      border: "1px solid gray",
                      borderRadius: 1,
                      backgroundImage: `url(${member.requirements[0].image_path})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <Typography
                      variant="span"
                      component="div"
                      sx={{ m: 9, color: "gray" }}
                    >
                      2x2 Picture
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {!loaded && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Editor;
