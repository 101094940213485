import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Banner from "../../imgs/members_background.jpg";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";

const MissionVision = (props) => {
  const theme = useTheme();
  const [members, setMembers] = useState(null);
  let lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <Box
          sx={{
            color: "#000",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            height: "auto",
            p: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12} md={12}>
              <Box sx={{ p: lg ? 3 : 2 }}>
                <Typography
                  variant={lg ? "h3" : "h4"}
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "primary.main",
                  }}
                >
                  OUR MISSION
                </Typography>
                <Typography
                  variant={lg ? "h4" : "h5"}
                  component="div"
                  sx={{
                    textAlign: "center",
                    color: "#000",
                    mt: 4,
                  }}
                >
                  "To empower real estate salespersons by cultivating their
                  inner potential in an environment of trust, stakeholder
                  collaboration, and inclusion in economic and advocarial
                  initiatives."
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} xs={12} md={12}>
              <Box sx={{ p: lg ? 3 : 2 }}>
                <Typography
                  variant={lg ? "h3" : "h4"}
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "danger.main",
                  }}
                >
                  OUR VISION
                </Typography>
                <Typography
                  variant={lg ? "h4" : "h5"}
                  component="div"
                  sx={{
                    textAlign: "center",
                    color: "#000",
                    mt: 4,
                  }}
                >
                  "To be the voice of the real estate salespersons in the
                  Philippines founded on the ideals of integrity, professional
                  competence, fair and equal opportunity, and representation."
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default MissionVision;
