import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Toast from "./Toast";
import AxiosInstance from "./config/AxiosInstance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80vh",
  overflow: "auto",
  borderRadius: "5px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MemberUserProfileModal = (props) => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({
    message: "",
    severinty: "",
  });
  const [inputs, setInputs] = useState({
    id: null,
    image: "",
    image_file: null,
    membership_city: "",
    membership_city_id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    province: "",
    province_id: null,
    city: "",
    city_id: null,
    barangay: "",
    barangay_id: null,
    street: "",
    business_address: "",
    gender: "",
    birth_date: "",
    TIN: "",
    landline: "",
    mobile_number: "",
    email: "",
    facebook_account: "",
    prc_resolution_number: "",
    prc_series_of: "",
    prc_validity: "",
    res_license: "",
    valid_prc_license: "",
    rec_prc_license: "",
    prc_license_num: "",
    prc_license_reg_date: "",
    prc_license_val_date: "",
    name_of_broker: "",
    reb_license_number: "",
    broker_validity: "",
    college: "",
    course: "",
    graduate_studies: "",
    in_case_name: "",
    in_case_address: "",
    in_case_contact_number: "",
  });

  const [errors, setErrors] = useState({ ...inputs });
  const [address, setAddress] = useState({
    membershipCities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    membershipCity: null,
    provinces: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    province: null,
    cities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    city: null,
    barangays: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    barangay: null,
  });

  const [gender, setGender] = useState({
    select: [
      {
        id: 1,
        label: "Male",
      },
      {
        id: 2,
        label: "Female",
      },
    ],
    value: null,
  });

  const [recPRCLicense, setRecPRCLicense] = useState({
    select: [
      {
        id: 1,
        label: "Real Estate Salesperson",
      },
      {
        id: 2,
        label: "Real Estate Broker License",
      },
      {
        id: 3,
        label: "Real Estate Appraiser License",
      },
    ],
    value: null,
  });

  const [resLicense, setResLicense] = useState({
    broker: false,
    appraiser: false,
    salesperson: false,
    license_for_renewal: false,
  });

  const [validPRCLicense, setValidPRCLicense] = useState({
    broker: false,
    appraiser: false,
    salesperson: false,
    license_for_renewal: false,
  });

  const handleChangeCheckBoxX = (e) => {
    setResLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeCheckBoxY = (e) => {
    setValidPRCLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeRecPRCLicense = (e, v) => {
    setRecPRCLicense((prev) => ({
      ...prev,
      value: v,
    }));

    set("rec_prc_license", v.label);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();

    const data = JSON.stringify(inputs);
    const isNotValidResLicense = Object.values(resLicense).every(
      (value) => value === false
    );

    const isNotValidPRCLicense = Object.values(validPRCLicense).every(
      (value) => value === false
    );

    if (isNotValidResLicense || isNotValidPRCLicense) {
      setToastOpen(true);
      setFeedback({
        message:
          "PRC Real Estate Licenses requires at least one response per row.",
        severinty: "error",
      });

      return;
    }

    const checkBoxX = JSON.stringify(resLicense);
    const checkBoxY = JSON.stringify(validPRCLicense);

    formData.append("id", inputs.id);
    formData.append("image", inputs.image_file);
    formData.append("membership_city", inputs.membership_city);
    formData.append("membership_city_id", inputs.membership_city_id);
    formData.append("first_name", inputs.first_name);
    formData.append("middle_name", inputs.middle_name);
    formData.append("last_name", inputs.last_name);
    formData.append("province", inputs.province);
    formData.append("province_id", inputs.province_id);
    formData.append("city", inputs.city);
    formData.append("city_id", inputs.city_id);
    formData.append("barangay", inputs.barangay);
    formData.append("barangay_id", inputs.barangay_id);
    formData.append("street", inputs.street);
    formData.append("business_address", inputs.business_address);
    formData.append("gender", inputs.gender);
    formData.append("birth_date", inputs.birth_date);
    formData.append("TIN", inputs.TIN);
    formData.append("landline", inputs.landline);
    formData.append("mobile_number", inputs.mobile_number);
    formData.append("email", inputs.email);
    formData.append("facebook_account", inputs.facebook_account);
    formData.append("prc_resolution_number", inputs.prc_resolution_number);
    formData.append("prc_series_of", inputs.prc_series_of);
    formData.append("prc_validity", inputs.prc_validity);
    formData.append("name_of_broker", inputs.name_of_broker);
    formData.append("reb_license_number", inputs.reb_license_number);
    formData.append("broker_validity", inputs.broker_validity);
    formData.append("college", inputs.college);
    formData.append("course", inputs.course);
    formData.append("graduate_studies", inputs.graduate_studies);
    formData.append("in_case_name", inputs.in_case_name);
    formData.append("in_case_address", inputs.in_case_address);
    formData.append("in_case_contact_number", inputs.in_case_contact_number);
    formData.append("res_license", checkBoxX);
    formData.append("valid_prc_license", checkBoxY);
    formData.append("rec_prc_license", inputs.rec_prc_license);
    formData.append("prc_license_num", inputs.prc_license_num);
    formData.append("prc_license_reg_date", inputs.prc_license_reg_date);
    formData.append("prc_license_val_date", inputs.prc_license_val_date);

    if (inputs.image !== "") {
      clearErrors();
      setSubmitting(true);
      AxiosInstance.post("/member/update", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setToastOpen(true);
          setFeedback({
            message: response.data.message,
            severinty: "success",
          });
          setSubmitting(false);
          localStorage.setItem("email", response.data.email);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          setSubmitting(false);
          const validation_errors = error.response.data.validation_errors;
          Object.keys(validation_errors).map((key) => {
            setErrors((prev) => ({
              ...prev,
              [key]: validation_errors[key][0],
            }));
          });
        });
    } else {
      setToastOpen(true);
      setFeedback({
        message: "Please select a photo.",
        severinty: "error",
      });
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    Object.keys(errors).map((key) => {
      clearError(key);
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleChange = (e) => {
    clearError(e.target.name);
    set(e.target.name, e.target.value);
  };

  const handleChangeCityMembership = (e, v) => {
    clearError("membership_city");
    resetAddressMembershipCity();
    setAddressData("membershipCity", v);

    const id = v.id;
    const name = v.label;

    set("membership_city", name);
    set("membership_city_id", id);
  };

  const handleChangeProvince = async (e, v) => {
    resetAddressCityBarangay();
    clearError("province");
    setAddressData("province", v);

    const id = v.id;
    const name = v.label;

    set("province", name);
    set("province_id", id);

    const response = await AxiosInstance.get("/cities", {
      params: {
        id: id,
        name: name,
      },
    });
    const data = response.data.map((city) => ({
      id: city.id,
      label: city.name,
      zipcode: city.zip_code,
    }));

    const uniqueCities = getUniqueAddressName(data);

    setAddressData("cities", uniqueCities);
  };

  const handleChangeCity = async (e, v) => {
    resetAddressBarangay();
    clearError("city");
    setAddressData("city", v);

    const id = v.id;
    const name = v.label;
    const code = v.zipcode;

    set("city", name);
    set("city_id", id);
    set("zipcode", code);

    const response = await AxiosInstance.get("/barangays", {
      params: {
        id: id,
        name: name,
      },
    });
    const data = response.data.map((city) => ({
      id: city.id,
      label: city.name,
    }));

    const uniqueBarangays = getUniqueAddressName(data);

    setAddressData("barangays", uniqueBarangays);
  };

  const handleChangeBarangay = (e, v) => {
    clearError("barangay");
    setAddressData("barangay", v);

    const id = v.id;
    const name = v.label;

    set("barangay", name);
    set("barangay_id", id);
  };

  const handleChangeGender = (e, v) => {
    clearError("gender");
    setGender((prev) => ({
      ...prev,
      value: v,
    }));

    set("gender", v.label);
  };

  const handleChangeUploadPhoto = (e) => {
    const fileObj = e.target.files[0];
    const url = URL.createObjectURL(fileObj);

    localStorage.setItem("image", url);

    set("image", url);
    set("image_file", fileObj);
  };

  const set = (key, value) => {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setAddressData = (key, value) => {
    setAddress((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetAddressCityBarangay = () => {
    setAddressData("city", null);
    setAddressData("barangay", null);
    set("city", "");
    set("city_id", null);
    set("barangay", "");
    set("barangay_id", null);
  };

  const resetAddressBarangay = () => {
    setAddressData("barangay", null);
    set("barangay", "");
    set("barangay_id", null);
  };

  const resetAddressMembershipCity = () => {
    setAddressData("membershipCity", null);
    set("membership_city", "");
    set("membership_city_id", null);
  };

  const clearError = (key) => {
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  const getUniqueAddressName = (data) => {
    const uniqeNames = [];

    const unique = data.filter((element) => {
      const isDuplicate = uniqeNames.includes(element.label);

      if (!isDuplicate) {
        uniqeNames.push(element.label);

        return true;
      }

      return false;
    });

    return unique;
  };

  const fetchMemberDetails = async () => {
    const response = await AxiosInstance.get("/member/details", {
      params: {
        email: localStorage.getItem("email"),
      },
    });

    return response;
  };

  const fetchCities = async (res) => {
    const response = await AxiosInstance.get("/membership/cities");

    const data = response.data.map((province) => {
      return province.cities.map((city, index) => {
        return {
          id: city.id,
          label: city.name + ", " + province.name,
        };
      });
    });

    let cities = [];

    for (let x = 0; x < data.length; x++) {
      data[x].map((city) => {
        cities.push({
          id: city.id,
          label: city.label,
        });
      });
    }

    const uniqueCities = getUniqueAddressName(cities);

    setAddressData("membershipCities", uniqueCities);

    const current_city = uniqueCities.find(
      (city) => city.id === res.city_membership[0].id
    );

    setAddressData("membershipCity", current_city);
  };

  const fetchProvinces = async () => {
    const response = await AxiosInstance.get("/provinces");
    const data = response.data.map((province) => ({
      id: province.id,
      label: province.name,
    }));

    const uniqueProvinces = getUniqueAddressName(data);

    setAddressData("provinces", uniqueProvinces);
  };

  const updateCurrentDetails = (res) => {
    const sex = gender.select.find((v) => v.label === res.data.gender);
    const prclicense = recPRCLicense.select.find(
      (v) => v.label === res.data.requirements[0].rec_prc_license
    );
    const data = res.data;
    if (data.requirements[0].res_license != "") {
      const tmpResLicense = JSON.parse(data.requirements[0].res_license);
      Object.keys(tmpResLicense).map((key) => {
        setResLicense((prev) => ({
          ...prev,
          [key]: tmpResLicense[key],
        }));
      });
    }

    if (data.requirements[0].valid_prc_license) {
      const tmpValidPRCLicense = JSON.parse(
        data.requirements[0].valid_prc_license
      );

      Object.keys(tmpValidPRCLicense).map((key) => {
        setValidPRCLicense((prev) => ({
          ...prev,
          [key]: tmpValidPRCLicense[key],
        }));
      });
    }

    fetchCities(res.data);
    fetchProvinces();

    setAddressData("province", {
      id: res.data.province[0].id,
      label: res.data.province[0].name,
    });

    setAddressData("city", {
      id: res.data.city[0].id,
      label: res.data.city[0].name,
    });

    setAddressData("barangay", {
      id: res.data.barangay[0].id,
      label: res.data.barangay[0].name,
    });

    setInputs({
      id: data.id,
      membership_city: data.city_membership[0].name,
      membership_city_id: data.city_membership[0].id,
      first_name: data.firstname,
      middle_name: data.middlename,
      last_name: data.lastname,
      province: data.province[0].name,
      province_id: data.province[0].id,
      city: data.city[0].name,
      city_id: data.city[0].id,
      barangay: data.barangay[0].name,
      barangay_id: data.barangay[0].id,
      street: data.member_address[0].street,
      business_address: data.business_address,
      gender: data.gender,
      birth_date: data.birth_date,
      TIN: data.TIN,
      landline: data.landline,
      mobile_number: data.mobile_number,
      email: data.email,
      facebook_account: data.facebook_account,
      prc_resolution_number: data.requirements[0].prc_resolution_number,
      prc_series_of: data.requirements[0].prc_series_of,
      prc_validity: data.requirements[0].validity_a,
      name_of_broker: data.requirements[0].broker_name,
      reb_license_number: data.requirements[0].reb_license_number,
      broker_validity: data.requirements[0].validity_b,
      college: data.member_educational_attainment[0].college,
      course: data.member_educational_attainment[0].course,
      graduate_studies: data.member_educational_attainment[0].graduate_studies,
      in_case_name: data.member_incase_of_emergency[0].incase_name,
      in_case_address: data.member_incase_of_emergency[0].incase_address,
      in_case_contact_number:
        data.member_incase_of_emergency[0].incase_contact_number,
      rec_prc_license: data.requirements[0].rec_prc_license,
      prc_license_num: data.requirements[0].prc_license_num,
      prc_license_reg_date: data.requirements[0].prc_license_reg_date,
      prc_license_val_date: data.requirements[0].prc_license_val_date,
    });

    set(
      "image",
      "https://acres.ph/backend/public/uploads/" +
        res.data.requirements[0].image_path
    );

    setGender((prev) => ({
      ...prev,
      value: sex,
    }));

    setRecPRCLicense((prev) => ({
      ...prev,
      value: prclicense,
    }));
  };

  useEffect(() => {
    fetchMemberDetails().then((res) => {
      updateCurrentDetails(res);
    });
  }, []);

  return (
    <>
      <Toast
        message={feedback.message}
        severinty={feedback.severinty}
        open={toastOpen}
        handleClose={handleCloseToast}
      />
      <Modal
        open={props.openModal}
        onClose={props.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleFormSubmit}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "gray" }}
          >
            Edit / update details
          </Typography>
          <Grid
            item
            lg={2}
            xs={7}
            md={8}
            sx={{ display: lg ? "block" : "none" }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: 200,
                  height: 200,
                  textAlign: "center",
                  border: "1px solid gray",
                  borderRadius: 1,
                  backgroundImage: `url(${inputs.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  mt: 3,
                }}
              >
                <Typography
                  variant="span"
                  component="div"
                  sx={{ m: 9, color: "gray" }}
                >
                  2x2 Picture
                </Typography>
              </Box>
            </Box>
            <FormControl fullWidth error>
              <TextField
                name="image"
                variant="outlined"
                size="small"
                type="file"
                onChange={handleChangeUploadPhoto}
                sx={{ mt: 3, mb: 1 }}
              />
              <FormHelperText>{errors.image}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                Basic info
              </Typography>
              <FormControl fullWidth error>
                <TextField
                  label="Firstname"
                  name="first_name"
                  variant="filled"
                  size="small"
                  value={inputs.first_name}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.first_name}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Middlename"
                  name="middle_name"
                  variant="filled"
                  size="small"
                  value={inputs.middle_name}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.middle_name}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Lastname"
                  name="last_name"
                  variant="filled"
                  size="small"
                  value={inputs.last_name}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.last_name}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Birthdate"
                  name="birth_date"
                  variant="filled"
                  size="small"
                  value={inputs.birth_date}
                  type="date"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.birth_date}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <Autocomplete
                  options={gender.select}
                  value={gender.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="gender"
                      type="text"
                      label="Gender"
                      variant="filled"
                      size="small"
                      sx={{ mt: 1, mb: 1 }}
                    />
                  )}
                  onChange={(event, value) => handleChangeGender(event, value)}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.gender}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                Contacts / TIN / Business Address
              </Typography>
              <FormControl fullWidth error>
                <TextField
                  label="Mobile number"
                  name="mobile_number"
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  value={inputs.mobile_number}
                  type="text"
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.mobile_number}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Email"
                  name="email"
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  value={inputs.email}
                  type="text"
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.email}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Facebook account"
                  name="facebook_account"
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  value={inputs.facebook_account}
                  type="text"
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.facebook_account}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Landline"
                  name="landline"
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  value={inputs.landline}
                  type="text"
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.landline}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Business address"
                  name="business_address"
                  variant="filled"
                  size="small"
                  onChange={handleChange}
                  value={inputs.business_address}
                  type="text"
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.business_address}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="TIN"
                  name="TIN"
                  variant="filled"
                  size="small"
                  value={inputs.TIN}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.TIN}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                Home Address / City Membership
              </Typography>
              <FormControl fullWidth error>
                <Autocomplete
                  options={address.provinces}
                  value={address.province}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Province"
                      variant="filled"
                      name="province"
                      type="text"
                      size="small"
                      sx={{ mt: 1, mb: 1 }}
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeProvince(event, value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.province}</FormHelperText>
              </FormControl>

              <FormControl fullWidth error>
                <Autocomplete
                  options={address.cities}
                  value={address.city}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      variant="filled"
                      name="city"
                      type="text"
                      size="small"
                      sx={{ mt: 1, mb: 1 }}
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeCity(event, value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.city}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <Autocomplete
                  options={address.barangays}
                  value={address.barangay}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Barangay"
                      variant="filled"
                      name="barangay"
                      type="text"
                      size="small"
                      sx={{ mt: 1, mb: 1 }}
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeBarangay(event, value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.barangay}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <Autocomplete
                  options={address.membershipCities}
                  value={address.membershipCity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City Membership"
                      variant="filled"
                      name="membership_city"
                      type="text"
                      size="small"
                      sx={{ mt: 1, mb: 1 }}
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeCityMembership(event, value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.membership_city}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Street/St./Rd./Blk./Zone"
                  name="street"
                  variant="filled"
                  size="small"
                  type="text"
                  value={inputs.street}
                  onChange={handleChange}
                />
                <FormHelperText>{errors.street}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={2}
              xs={7}
              md={8}
              sx={{ display: lg ? "none" : "block" }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    textAlign: "center",
                    border: "1px solid gray",
                    borderRadius: 1,
                    backgroundImage: `url(${inputs.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ m: 9, color: "gray" }}
                  >
                    2x2 Picture
                  </Typography>
                </Box>
              </Box>
              <FormControl fullWidth error>
                <TextField
                  name="image"
                  variant="outlined"
                  size="small"
                  type="file"
                  onChange={handleChangeUploadPhoto}
                  sx={{ mt: 3, mb: 1 }}
                />
                <FormHelperText>{errors.image}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <FormLabel component="legend">
            <Typography
              variant={lg ? "h5" : "h6"}
              component="div"
              sx={{ textAlign: "left", mb: 3, mt: 5 }}
            >
              PRC Real Estate Licenses
            </Typography>
          </FormLabel>
          {!lg && (
            <>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}></Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Salesperson
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Broker
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Appraiser
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    License for Renewal
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Real Estate License
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="broker"
                        checked={resLicense.broker}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="appraiser"
                        checked={resLicense.appraiser}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="salesperson"
                        checked={resLicense.salesperson}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="license_for_renewal"
                        checked={resLicense.license_for_renewal}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="p" component="div">
                    Which PRC License is valid (not expired today)?
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="broker"
                        checked={validPRCLicense.broker}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="appraiser"
                        checked={validPRCLicense.appraiser}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="salesperson"
                        checked={validPRCLicense.salesperson}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="license_for_renewal"
                        checked={validPRCLicense.license_for_renewal}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          {lg && (
            <>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={2} md={12}></Grid>
                <Grid item lg={4} xs={4} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 15 }}
                  >
                    Real Estate License
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={6} md={12}>
                  <Typography variant="p" component="div" sx={{ fontSize: 15 }}>
                    Which PRC License is valid (not expired today)?
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={2} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 15 }}
                  >
                    Salesperson
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={4} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="salesperson"
                        checked={resLicense.salesperson}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={4} xs={6} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="salesperson"
                        checked={validPRCLicense.salesperson}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={2} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 15 }}
                  >
                    Broker
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={4} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="broker"
                        checked={resLicense.broker}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={4} xs={6} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="broker"
                        checked={validPRCLicense.broker}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={2} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 15 }}
                  >
                    Appraiser
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={4} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="appraiser"
                        checked={resLicense.appraiser}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={4} xs={6} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="appraiser"
                        checked={validPRCLicense.appraiser}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={2} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 15 }}
                  >
                    License for Renewal
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={4} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="license_for_renewal"
                        checked={resLicense.license_for_renewal}
                        onChange={handleChangeCheckBoxX}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={4} xs={6} md={12} sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="license_for_renewal"
                        checked={validPRCLicense.license_for_renewal}
                        onChange={handleChangeCheckBoxY}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div">
                Select PRC Real Estate License
              </Typography>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <FormControl fullWidth error>
                <Autocomplete
                  options={recPRCLicense.select}
                  value={recPRCLicense.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="rec_prc_license"
                      type="text"
                      label="Choose"
                      variant="filled"
                      size="small"
                    />
                  )}
                  onChange={(event, value) =>
                    handleChangeRecPRCLicense(event, value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === "" ||
                    option.id === value.id
                  }
                />
                <FormHelperText>{errors.rec_prc_license}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <FormControl fullWidth error>
                <TextField
                  name="prc_license_num"
                  label="PRC License Number"
                  variant="filled"
                  size="small"
                  type="text"
                  value={inputs.prc_license_num}
                  onChange={handleChange}
                />
                <FormHelperText>{errors.prc_license_num}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div">
                Registration Date (see PRC ID)
              </Typography>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <FormControl fullWidth error>
                <TextField
                  type="date"
                  name="prc_license_reg_date"
                  variant="filled"
                  size="small"
                  value={inputs.prc_license_reg_date}
                  onChange={handleChange}
                />
                <FormHelperText>{errors.prc_license_reg_date}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div">
                Valid Until (see PRC ID)
              </Typography>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <FormControl fullWidth error>
                <TextField
                  type="date"
                  name="prc_license_val_date"
                  variant="filled"
                  size="small"
                  value={inputs.prc_license_val_date}
                  onChange={handleChange}
                />
                <FormHelperText>{errors.prc_license_val_date}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                Accreditation Details
              </Typography>
              <FormControl fullWidth error>
                <TextField
                  label="PRC Resolution Number"
                  name="prc_resolution_number"
                  variant="filled"
                  size="small"
                  value={inputs.prc_resolution_number}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.prc_resolution_number}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Series of"
                  name="prc_series_of"
                  variant="filled"
                  size="small"
                  value={inputs.prc_series_of}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.prc_series_of}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Validity"
                  name="prc_validity"
                  variant="filled"
                  size="small"
                  value={inputs.prc_validity}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.prc_validity}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <Box sx={{ mt: 3 }}></Box>
              <FormControl fullWidth error>
                <TextField
                  label="Name of broker"
                  name="name_of_broker"
                  variant="filled"
                  size="small"
                  value={inputs.name_of_broker}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.name_of_broker}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="REB License Number"
                  name="reb_license_number"
                  variant="filled"
                  size="small"
                  value={inputs.reb_license_number}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.reb_license_number}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Validity"
                  name="broker_validity"
                  variant="filled"
                  size="small"
                  value={inputs.broker_validity}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.broker_validity}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                Educational Attainment / Special Courses
              </Typography>
              <FormControl fullWidth error>
                <TextField
                  label="College / School / University"
                  name="college"
                  variant="filled"
                  size="small"
                  value={inputs.college}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.college}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Course / Bachelor's Degree"
                  name="course"
                  variant="filled"
                  size="small"
                  value={inputs.course}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.course}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Graduate Studies / Master's Degree, PhD, etc."
                  name="graduate_studies"
                  variant="filled"
                  size="small"
                  value={inputs.graduate_studies}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.graduate_studies}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={12} md={12}>
              <Typography variant="p" component="div" sx={{ color: "gray" }}>
                In Case Of Emergency
              </Typography>
              <FormControl fullWidth error>
                <TextField
                  label="Name"
                  name="in_case_name"
                  variant="filled"
                  size="small"
                  value={inputs.in_case_name}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.in_case_name}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Address"
                  name="in_case_address"
                  variant="filled"
                  size="small"
                  value={inputs.in_case_address}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.in_case_address}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error>
                <TextField
                  label="Contact Number"
                  name="in_case_contact_number"
                  variant="filled"
                  size="small"
                  value={inputs.in_case_contact_number}
                  type="text"
                  onChange={handleChange}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormHelperText>{errors.in_case_contact_number}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {!submitting && (
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="primary"
            >
              SAVE DETAILS
            </Button>
          )}
          {submitting && (
            <LoadingButton loading variant="contained">
              SUBMITTING ...
            </LoadingButton>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default MemberUserProfileModal;
