import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AppBarLogo from "../imgs/logo1.png";
import DrawerLogo from "../imgs/acres_white.png";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import AxiosInstance from "./config/AxiosInstance";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const drawerWidth = 240;

export default function Header(props) {
  const loggedIn = localStorage.getItem("authToken") !== null;
  const navItems = [
    {
      auth: true,
      path: "/",
      name: "Home",
    },
    {
      auth: true,
      path: "/news",
      name: "News",
    },
    {
      auth: true,
      path: "/who-we-are",
      name: "Who We Are",
    },
    {
      auth: true,
      path: "/mission-vision",
      name: "Mission & Vision",
    },
    {
      auth: true,
      path: "/members",
      name: "Members",
    },
    {
      auth: !loggedIn,
      path: "/join-now",
      name: "Join Now",
    },
    {
      auth: !loggedIn,
      path: "/member/login",
      name: "Login",
    },
    {
      auth: loggedIn,
      path: "/member/user/profile",
      name: "Profile",
    },
    {
      auth: loggedIn,
      path: "",
      name: "Logout",
    },
  ];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    AxiosInstance.post("/member/logout").then((response) => {
      if (response.data.status == 200) {
        if (localStorage.getItem("authToken") !== null) {
          localStorage.removeItem("authToken");
          setTimeout(() => {
            navigate("/member/login");
          }, 500);
        }
      }
    });
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: "primary.main",
        height: "100%",
        color: "#fff",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
        }}
      >
        <img src={DrawerLogo} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            {item.auth && (
              <ListItemButton sx={{ textAlign: "left" }}>
                <Link
                  key={item.name}
                  to={`${item.path}`}
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={() => {
                    item.name == "Logout" && handleLogout();
                  }}
                >
                  {item.name}
                </Link>
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar sx={{ backgroundColor: "#fff", paddingTop: 1 }}>
          <Container>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <img src={AppBarLogo} />
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item) => (
                  <Link
                    key={item.name}
                    to={`${item.path}`}
                    style={{
                      textDecoration: "none",
                      color:
                        location.pathname === item.path && item.name == "Login"
                          ? "#fff"
                          : "#000",
                    }}
                    onClick={() => {
                      item.name == "Logout" && handleLogout();
                    }}
                  >
                    {item.auth && item.name != "Login" && (
                      <Button
                        color={item.name == "Login" ? "danger" : "primary"}
                        variant={
                          location.pathname === item.path
                            ? "contained"
                            : "outlined"
                        }
                        disableElevation
                        sx={{ ml: 2, borderRadius: 0 }}
                      >
                        {item.name}
                      </Button>
                    )}
                    {item.auth && item.name == "Login" && (
                      <Button
                        color={item.name == "Login" ? "danger" : "primary"}
                        variant={
                          location.pathname === item.path
                            ? "contained"
                            : "outlined"
                        }
                        disableElevation
                        sx={{ ml: 2, borderRadius: 0 }}
                      >
                        <AccountCircleIcon /> &nbsp; Login
                      </Button>
                    )}
                  </Link>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
