import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Link from "@mui/material/Link";
import AxiosInstance from "../config/AxiosInstance";
import Toast from "../Toast";
import Header from "../Header";
import Footer from "../Footer";

const AIPOReg = () => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.up("lg"));
  const [membershipStatus, setMembershipStatus] = useState("Yes");
  const [paymentStatus, setPaymentStatus] = useState("Paid");
  const [forwarded, setForwarded] = useState("Yes");
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedback, setFeedback] = useState({
    message: "",
    severinty: "",
  });
  const [inputs, setInputs] = useState({
    image: "",
    payment_file: null,
    firstname: "",
    middlename: "",
    lastname: "",
    province: "",
    province_id: null,
    city: "",
    city_id: null,
    sex: "",
    contact: "",
    email: "",
    res_license: "",
    valid_prc_license: "",
    rec_prc_license: "",
    prc_license_num: "",
    prc_license_reg_date: "",
    prc_license_val_date: "",
    mem_status: "",
    payment_status: "",
    curr_mem_chapter: "",
    co_practitioner: "",
    is_forwarded: "Yes",
  });
  const [errors, setErrors] = useState({ ...inputs });

  const handleChangeMembershipStatus = (event) => {
    setMembershipStatus(event.target.value);
    set("mem_status", event.target.value);
  };

  const handleChangePaymentStatus = (event) => {
    setPaymentStatus(event.target.value);
    set("payment_status", event.target.value);
  };

  const handleChangeForwarded = (event) => {
    setForwarded(event.target.value);
    set("is_forwarded", event.target.value);
  };

  const handleChange = (e) => {
    // clearError(e.target.name);
    set(e.target.name, e.target.value);
  };

  const [sex, setSex] = useState({
    select: [
      {
        id: 1,
        label: "Male",
      },
      {
        id: 2,
        label: "Female",
      },
    ],
    value: null,
  });

  const [recPRCLicense, setRecPRCLicense] = useState({
    select: [
      {
        id: 1,
        label: "Real Estate Broker License",
      },
      {
        id: 2,
        label: "Real Estate Appraiser License",
      },
      {
        id: 3,
        label: "Real Estate Consultant License",
      },
    ],
    value: null,
  });

  const [address, setAddress] = useState({
    provinces: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    province: null,
    cities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    city: null,
  });

  const [resLicense, setResLicense] = useState({
    broker: false,
    appraiser: false,
    consultant: false,
    license_for_renewal: false,
  });

  const [validPRCLicense, setValidPRCLicense] = useState({
    broker: false,
    appraiser: false,
    consultant: false,
    license_for_renewal: false,
  });

  const set = (key, value) => {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeCheckBoxX = (e) => {
    setResLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeCheckBoxY = (e) => {
    setValidPRCLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeSex = (e, v) => {
    setSex((prev) => ({
      ...prev,
      value: v,
    }));

    set("sex", v.label);
  };

  const handleChangeRecPRCLicense = (e, v) => {
    setRecPRCLicense((prev) => ({
      ...prev,
      value: v,
    }));

    set("rec_prc_license", v.label);
  };

  const setAddressData = (key, value) => {
    setAddress((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getUniqueAddressName = (data) => {
    const uniqueNames = [];

    const unique = data.filter((element) => {
      const isDuplicate = uniqueNames.includes(element.label);

      if (!isDuplicate) {
        uniqueNames.push(element.label);

        return true;
      }

      return false;
    });

    return unique;
  };

  const handleChangeProvince = async (e, v) => {
    resetAddressCity();
    // clearError("province");
    setAddressData("province", v);

    const id = v.id;
    const name = v.label;

    set("province", name);
    set("province_id", id);

    const response = await AxiosInstance.get("/cities", {
      params: {
        id: id,
        name: name,
      },
    });
    const data = response.data.map((city) => ({
      id: city.id,
      label: city.name,
      zipcode: city.zip_code,
    }));

    const uniqueCities = getUniqueAddressName(data);

    setAddressData("cities", uniqueCities);
  };

  const handleChangeCity = async (e, v) => {
    // clearError("city");
    setAddressData("city", v);

    const id = v.id;
    const name = v.label;
    const code = v.zipcode;

    set("city", name);
    set("city_id", id);
  };

  const handleChangeUploadFile = (e) => {
    const fileObj = e.target.files[0];
    const url = URL.createObjectURL(fileObj);

    localStorage.setItem("image", url);

    set("image", url);
    set("payment_file", fileObj);
  };

  const resetAddressCity = () => {
    setAddressData("city", null);
    set("city", "");
    set("city_id", null);
  };

  const handleFormSubmit = (e) => {
    const checkBoxX = JSON.stringify(resLicense);
    const checkBoxY = JSON.stringify(validPRCLicense);
    const formData = new FormData();

    formData.append("payment_file", inputs.payment_file);
    formData.append("email", inputs.email);
    formData.append("firstname", inputs.firstname);
    formData.append("middlename", inputs.middlename);
    formData.append("lastname", inputs.lastname);
    formData.append("sex", inputs.sex);
    formData.append("contact", inputs.contact);
    formData.append("res_license", checkBoxX);
    formData.append("valid_prc_license", checkBoxY);
    formData.append("rec_prc_license", inputs.rec_prc_license);
    formData.append("prc_license_num", inputs.prc_license_num);
    formData.append("prc_license_reg_date", inputs.prc_license_reg_date);
    formData.append("prc_license_val_date", inputs.prc_license_val_date);
    formData.append("province", inputs.province);
    formData.append("province_id", inputs.province_id);
    formData.append("city", inputs.city);
    formData.append("city_id", inputs.city_id);
    formData.append("mem_status", inputs.mem_status);
    formData.append("payment_status", inputs.payment_status);
    formData.append("curr_mem_chapter", inputs.curr_mem_chapter);
    formData.append("co_practitioner", inputs.co_practitioner);
    formData.append("is_forwarded", inputs.is_forwarded);

    AxiosInstance.post("/aipo-form-data", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      //   setToastOpen(true);
      //   setFeedback({
      //     message: response.data.message,
      //     severinty: "success",
      //   });
      //   setSubmitting(false);
      //   setTimeout(() => {
      //     setSuccess(true);
      //   }, 1000);
    });
    //   .catch((error) => {
    //     setSubmitting(false);
    //     const validation_errors = error.response.data.validation_errors;
    //     Object.keys(validation_errors).map((key) => {
    //       setErrors((prev) => ({
    //         ...prev,
    //         [key]: validation_errors[key][0],
    //       }));
    //     });
    //   });
    e.preventDefault();
  };

  useEffect(() => {
    const fetchCities = async () => {
      const response = await AxiosInstance.get("/membership/cities");

      const data = response.data.map((province) => {
        return province.cities.map((city, index) => {
          return {
            id: city.id,
            label: city.name + ", " + province.name,
          };
        });
      });

      let cities = [];

      for (let x = 0; x < data.length; x++) {
        data[x].map((city) => {
          cities.push({
            id: city.id,
            label: city.label,
          });
        });
      }

      const uniqueCities = getUniqueAddressName(cities);

      setAddressData("membershipCities", uniqueCities);
    };

    const fetchProvinces = async () => {
      const response = await AxiosInstance.get("/provinces");
      const data = response.data.map((province) => ({
        id: province.id,
        label: province.name,
      }));

      const uniqueProvinces = getUniqueAddressName(data);

      setAddressData("provinces", uniqueProvinces);
    };

    fetchCities();
    fetchProvinces();
  }, []);

  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{
          backgroundColor: "gray",
          pt: 5,
          pb: 5,
        }}
      >
        <Container>
          <Paper sx={{ mt: 3, mb: 3 }}>
            <Box
              sx={{ width: "100%", height: "auto", padding: 5 }}
              component="form"
              onSubmit={handleFormSubmit}
            >
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={6} xs={12} md={12}>
                  <Typography
                    variant="h1"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    <Typography
                      variant="span"
                      component="span"
                      sx={{ color: "primary.main" }}
                    >
                      Phil
                    </Typography>
                    <Typography
                      variant="span"
                      component="span"
                      sx={{ color: "danger.main" }}
                    >
                      RES
                    </Typography>
                  </Typography>
                </Grid>
                {/* <Grid item lg={6} xs={12} md={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    AIPO National Membership Registration Form
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{ color: "gray" }}
                  >
                    <i>
                      <strong>By signing this form, I hereby grant</strong> my
                      free, voluntary and unconditional consent to the
                      collection and processing of my Personal and Professional
                      Data by PhilRES for purposes of registration in compliance
                      with{" "}
                      <strong>
                        Section 34 of RA 9646 in relation to Section 38 or Real
                        Estate Service Act of 1996.
                      </strong>
                    </i>
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2, mt: 5 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Email Address
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      label="Your email address"
                      name="email"
                      variant="filled"
                      size="small"
                      type="text"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              <FormLabel component="legend">
                <Typography
                  variant={lg ? "h5" : "h6"}
                  component="div"
                  sx={{ textAlign: "center", mb: 3 }}
                >
                  APPLICATION FOR MEMBERSHIP
                </Typography>
              </FormLabel>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Full Name
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      label="Last Name"
                      name="lastname"
                      variant="filled"
                      size="small"
                      type="text"
                      value={inputs.lastname}
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      label="First Name"
                      name="firstname"
                      variant="filled"
                      size="small"
                      type="text"
                      value={inputs.firstname}
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      label="Middle Name"
                      name="middlename"
                      variant="filled"
                      size="small"
                      type="text"
                      value={inputs.middlename}
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Sex / Contact Number
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <Autocomplete
                      options={sex.select}
                      value={sex.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="sex"
                          type="text"
                          label="Sex"
                          variant="filled"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => handleChangeSex(event, value)}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.id === value.id
                      }
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      name="contact"
                      label="Contact Number"
                      variant="filled"
                      size="small"
                      type="text"
                      value={inputs.contact}
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <FormLabel component="legend" sx={{ mt: 3 }}>
                <Typography
                  variant={lg ? "h5" : "h6"}
                  component="div"
                  sx={{ textAlign: "center", mb: 3 }}
                >
                  What are your PRC Real Estate Licenses?
                </Typography>
              </FormLabel>
              {lg && (
                <>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={12} md={12}></Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <Typography variant="h6" component="div">
                        Broker
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <Typography variant="h6" component="div">
                        Appraiser
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <Typography variant="h6" component="div">
                        Consultant
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <Typography variant="h6" component="div">
                        License for Renewal
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={12} md={12}>
                      <Typography variant="h6" component="div">
                        Real Estate License
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="broker"
                            checked={resLicense.broker}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="appraiser"
                            checked={resLicense.appraiser}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="consultant"
                            checked={resLicense.consultant}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="license_for_renewal"
                            checked={resLicense.license_for_renewal}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={12} md={12}>
                      <Typography variant="p" component="div">
                        Which PRC License is valid (not expired today)?
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="broker"
                            checked={validPRCLicense.broker}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="appraiser"
                            checked={validPRCLicense.appraiser}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="consultant"
                            checked={validPRCLicense.consultant}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                    <Grid item lg={2} xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="license_for_renewal"
                            checked={validPRCLicense.license_for_renewal}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {!lg && (
                <>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={2} md={12}></Grid>
                    <Grid item lg={4} xs={4} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        Real Estate License
                      </Typography>
                    </Grid>
                    <Grid item lg={4} xs={6} md={12}>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        Which PRC License is valid (not expired today)?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={2} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        Broker
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={4}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="broker"
                            checked={resLicense.broker}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={6}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="broker"
                            checked={validPRCLicense.broker}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={2} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        Appraiser
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={4}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="appraiser"
                            checked={resLicense.appraiser}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={6}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="appraiser"
                            checked={validPRCLicense.appraiser}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={2} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        Consultant
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={4}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="consultant"
                            checked={resLicense.consultant}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={6}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="consultant"
                            checked={validPRCLicense.consultant}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item lg={3} xs={2} md={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: 15 }}
                      >
                        License for Renewal
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={4}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="license_for_renewal"
                            checked={resLicense.license_for_renewal}
                            onChange={handleChangeCheckBoxX}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={6}
                      md={12}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="license_for_renewal"
                            checked={validPRCLicense.license_for_renewal}
                            onChange={handleChangeCheckBoxY}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="p" component="div">
                    For AIPO Registration, choose which PRC Real Estate License
                    for recording.
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <Autocomplete
                      options={recPRCLicense.select}
                      value={recPRCLicense.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="rec_prc_license"
                          type="text"
                          label="Choose"
                          variant="filled"
                          size="small"
                        />
                      )}
                      onChange={(event, value) =>
                        handleChangeRecPRCLicense(event, value)
                      }
                      isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.id === value.id
                      }
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      name="prc_license_num"
                      label="PRC License Number"
                      variant="filled"
                      size="small"
                      type="text"
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="p" component="div">
                    Registration Date (see PRC ID)
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      type="date"
                      name="prc_license_reg_date"
                      variant="filled"
                      size="small"
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="p" component="div">
                    Valid Until (see PRC ID) *
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <TextField
                      type="date"
                      name="prc_license_val_date"
                      variant="filled"
                      size="small"
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Address
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <Autocomplete
                      options={address.provinces}
                      value={address.province}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="province"
                          type="text"
                          label="Province"
                          variant="filled"
                        />
                      )}
                      onChange={(event, value) =>
                        handleChangeProvince(event, value)
                      }
                      isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.id === value.id
                      }
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <FormControl fullWidth error>
                    <Autocomplete
                      options={address.cities}
                      value={address.city}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          type="text"
                          label="City / Municipality"
                          variant="filled"
                        />
                      )}
                      onChange={(event, value) =>
                        handleChangeCity(event, value)
                      }
                      isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.id === value.id
                      }
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Membership Status
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={membershipStatus === "Yes"}
                        onChange={handleChangeMembershipStatus}
                        value="Yes"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "Yes" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }}>
                      <Typography variant="h6" component="div">
                        Existing Member
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={membershipStatus === "No"}
                        onChange={handleChangeMembershipStatus}
                        value="No"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "No" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }}>
                      <Typography variant="h6" component="div">
                        First Time Member
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <FormLabel component="legend">
                <Typography variant="p" component="div" sx={{ mb: 3 }}>
                  New Member: One-Time Registration Fee of <strong>P500</strong>{" "}
                  <br />
                  Existing Member: 2023 National Annual Fee of{" "}
                  <strong>P500</strong> <br />
                  Account: Philippine Institute of Real Estate Service
                  Practitioners (Philres) Inc. <br />
                  Bank Account: BDO Savings Account 000-558-023-769 <br />
                  Branch: Maysilo, Boni, Mandaluyong <br />
                  (Note: You can pay using ie BDO, GCash, Other Online Banks)
                </Typography>
              </FormLabel> */}
              {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Payment Status
                  </Typography>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={paymentStatus === "Paid"}
                        onChange={handleChangePaymentStatus}
                        value="Paid"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "Paid" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }}>
                      <Typography variant="h6" component="div">
                        I have paid the fee.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={paymentStatus === "Unpaid"}
                        onChange={handleChangePaymentStatus}
                        value="Unpaid"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "Unpaid" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }} lg={10} xs={10}>
                      <Typography variant="h6" component="div">
                        I have yet to pay the fee to PhilRES' BDO Account.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              {/* <Grid container sx={{ mt: 3 }}>
                <Grid item lg={8}>
                  <FormLabel component="legend">
                    <Typography variant="p" component="div" sx={{ mb: 3 }}>
                      NOTE #1: If paid, you may upload proof of payment in this
                      Form. <br />
                      NOTE #2: If not yet, pay to the PhilRES BDO Account above
                      and PM the proof of payment <br /> to{" "}
                      <Link
                        href="https://www.facebook.com/philresfbpage"
                        sx={{ textDecoration: "none", color: "primary.main" }}
                      >
                        {" "}
                        https://www.facebook.com/philresfbpage
                      </Link>
                    </Typography>
                  </FormLabel>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <FormControl fullWidth error>
                    <TextField
                      type="file"
                      name="payment_file"
                      variant="outlined"
                      size="small"
                      onChange={handleChangeUploadFile}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3 }}>
                <Grid item lg={8}>
                  <FormLabel component="legend">
                    <Typography variant="p" component="div" sx={{ mb: 3 }}>
                      (optional) FOR CURRENT MEMBER, indicate chapter. ( If you
                      forget, click and PM / private message
                      <Link
                        href="https://www.facebook.com/philresfbpage"
                        sx={{ textDecoration: "none", color: "primary.main" }}
                      >
                        {" "}
                        https://www.facebook.com/philresfbpage
                      </Link>{" "}
                      )
                    </Typography>
                  </FormLabel>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <FormControl fullWidth error>
                    <TextField
                      type="text"
                      name="curr_mem_chapter"
                      variant="filled"
                      size="small"
                      label="Optional Text"
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3 }}>
                <Grid item lg={8}>
                  <FormLabel component="legend">
                    <Typography variant="p" component="div" sx={{ mb: 3 }}>
                      Who is the co-practitioner that encouraged you to fill-up
                      the Form so we can <br />
                      at least recognize his/her commiment in unifying the
                      industry? (optional)
                    </Typography>
                  </FormLabel>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <FormControl fullWidth error>
                    <TextField
                      type="text"
                      name="co_practitioner"
                      variant="filled"
                      size="small"
                      label="Optional Text"
                      onChange={handleChange}
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <FormLabel component="legend" sx={{ mt: 3 }}>
                <Typography variant="p" component="div" sx={{ mb: 3 }}>
                  Will you forward this RESA Compliance Form to a
                  co-practitioner and <br /> help unify the industry? (optional)
                </Typography>
              </FormLabel> */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={forwarded === "Yes"}
                        onChange={handleChangeForwarded}
                        value="Yes"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "Yes" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }}>
                      <Typography variant="h6" component="div">
                        Yes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        checked={forwarded === "No"}
                        onChange={handleChangeForwarded}
                        value="No"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "No" }}
                      />
                    </Grid>
                    <Grid item sx={{ pt: 0.5 }} lg={10} xs={10}>
                      <Typography variant="h6" component="div">
                        No
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                >
                  SUBMIT
                </Button>
              </FormControl>
            </Box>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default AIPOReg;
