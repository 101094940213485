import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import JoinNow from "./components/pages/JoinNow";
import Members from "./components/pages/Members";
import WhoWeAre from "./components/pages/WhoWeAre";
import MissionVision from "./components/pages/MissionVision";
import Error from "./components/pages/Error";
import Editor from "./components/pages/Editor";
import MemberLogin from "./components/pages/MemberLogin";
import MemberUserProfile from "./components/pages/MemberUserProfile";
import RequireUnAuth from "./components/pages/RequireUnAuth";
import RequireAuth from "./components/pages/RequireAuth";
import AxiosInstance from "./components/config/AxiosInstance";
import AIPOReg from "./components/pages/AIPOReg";
import News from "./components/pages/News";

AxiosInstance.defaults.withCredentials = true;
AxiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const mdTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#2d50d3",
      },
      secondary: {
        main: "#f50057",
      },
      success: {
        main: "#0F5818",
      },
      danger: {
        main: "#db2a2a",
      },
      dark: {
        main: "#354455",
      },
    },
  });
  return (
    <ThemeProvider theme={mdTheme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/mission-vision" element={<MissionVision />} />
        <Route path="/members" element={<Members />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/news" element={<News />} />

        <Route element={<RequireUnAuth />}>
          <Route path="/member/login" element={<MemberLogin />} />
          <Route path="/join-now" element={<JoinNow />} />
          <Route path="/aipo-reg" element={<AIPOReg />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path="/member/user/profile" element={<MemberUserProfile />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
