import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Banner from "../../imgs/intro_banner.PNG";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const WhoWeAre = (props) => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: "white",
          height: lg ? "90vh" : "auto",
        }}
      >
        <Box
          sx={{
            color: "#000",
            p: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} md={12}>
              <Typography
                variant={lg ? "h3" : "h4"}
                component="div"
                sx={{
                  color: "#000",
                  textAlign: lg ? "left" : "center",
                  mb: 5,
                  mt: 3,
                  fontWeight: "bold",
                }}
              >
                WHO WE ARE
              </Typography>
              <Typography
                variant={lg ? "h6" : "p"}
                component="div"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  mb: lg ? 3 : 1,
                }}
              >
                ACCREDITED REAL ESTATE SALESPERSONS <br /> OF THE PHILIPPINES
                INC.
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12} md={12}>
              <Typography
                variant="p"
                component="div"
                sx={{
                  color: "#000",
                  mt: 3,
                }}
              >
                ACRES Philippines is the country’s first and largest
                organization of accredited real estate salespersons Seeking to
                work harmoniously and collaboratively with professionals in the
                real estate service, regulatory agencies, real estate
                organizations, property companies, policy makers, advocacy
                groups.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} md={12}>
              <br />
              <Typography
                variant="p"
                component="div"
                sx={{
                  color: "#000",
                }}
              >
                Founded in 2021 and registered by the Securities and Exchange
                Commission, ACRES supports the endeavors of salespersons in
                furthering their learnings, ethical standards, protection of
                their rights to free and fair practice, upliftment of their
                well-being, and due representation and inclusion on matters of
                their trade and prosperity.
              </Typography>
              <br />
              <Typography
                variant="p"
                component="div"
                sx={{
                  color: "#000",
                }}
              >
                So as to ensure their professional development, widen their
                business opportunities and improve their economic standing in
                life.
                <br />
                <br />
                ACRES is also a memberorganization of A BetterReal Estate
                Philippines(ABREP).
              </Typography>
              <Link
                to={`/join-now`}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  sx={{ mt: 3 }}
                >
                  Join Now
                </Button>
              </Link>
            </Grid>
            <Grid item lg={6} xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={Banner}
                  alt="Who we are image"
                  width={lg ? 700 : 320}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default WhoWeAre;
