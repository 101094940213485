import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ErrorIcon from "@mui/icons-material/Error";
import Header from "../Header";
import Footer from "../Footer";

const Error = (props) => {
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ m: 3, textAlign: "center", height: "50vh", pt: 15 }}>
          <Typography variant="h4" sx={{ color: "inherit" }}>
            <ErrorIcon sx={{ fontSize: "30px" }} /> Page Not found
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Error;
