import { useLocation, Navigate, Outlet } from "react-router-dom";
import { Box, Container, CssBaseline, CircularProgress } from "@mui/material";
import useAuthenticated from "../config/Authenticate";
import Header from "../Header";
import Footer from "../Footer";

const RequireAuth = () => {
  const location = useLocation();
  const auth = useAuthenticated();

  if (auth === null) {
    return (
      <>
        <Header />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 30,
              marginBottom: 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          </Box>
        </Container>
        <Footer />
      </>
    );
  }

  return auth ? (
    <Outlet />
  ) : (
    <Navigate to="/member/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
