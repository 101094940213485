import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import AxiosInstance from "../config/AxiosInstance";
import Toast from "../Toast";
import LogoMonogram from "../../imgs/acres_monogram.png";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const MemberLogin = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    verification_code: "",
  });
  const [errors, setErrors] = useState({ ...inputs });
  const [verify, setVerify] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedback, setFeedback] = useState({
    message: "",
    severinty: "",
  });

  const handleVerify = () => {
    clearInputs();
    clearErrors();
    setVerify(true);
    setVerifying(false);
  };

  const handleReset = () => {
    clearInputs();
    clearErrors();
    setVerify(false);
    setVerifying(false);
    setSuccess(false);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleChange = (e) => {
    clearError(e.target.name);
    set(e.target.name, e.target.value);
  };

  const clearErrors = () => {
    Object.keys(errors).map((key) => {
      clearError(key);
    });
  };

  const responseError = (error) => {
    setSubmitting(false);
    const validation_errors = error.response.data.validation_errors;
    Object.keys(validation_errors).map((key) => {
      setErrors((prev) => ({
        ...prev,
        [key]: validation_errors[key][0],
      }));
    });
  };

  const set = (key, value) => {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const clearError = (key) => {
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  const clearInputs = () => {
    Object.keys(errors).map((key) => {
      setInputs((prev) => ({
        ...prev,
        [key]: "",
      }));
    });
  };

  const storeAuthToken = (token) => {
    localStorage.setItem("authToken", token);
  };

  const clearAuthToken = () => {
    localStorage.removeItem("authToken");
  };
  const handleSubmitUserVerify = async (e) => {
    e.preventDefault();
    clearErrors();
    const verify = {
      email: inputs.email,
    };

    const data = JSON.stringify(verify);
    setSubmitting(true);

    const response = await AxiosInstance.post("/user/verification", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setToastOpen(true);
        setFeedback({
          message: response.data.message,
          severinty: "success",
        });
        setSubmitting(false);
        setVerifying(true);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    clearErrors();
    const verifying = {
      email: inputs.email,
      verification_code: inputs.verification_code,
    };

    const data = JSON.stringify(verifying);
    setSubmitting(true);

    const response = await AxiosInstance.post("/user/verification/code", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setToastOpen(true);
        setFeedback({
          message: response.data.message,
          severinty: "success",
        });
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    clearErrors();
    const login = {
      email: inputs.email,
      password: inputs.password,
    };

    const data = JSON.stringify(login);
    setSubmitting(true);

    const response = await AxiosInstance.post("/user/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setToastOpen(true);
        setFeedback({
          message: response.data.message,
          severinty: "success",
        });
        setSubmitting(false);
        storeAuthToken(response.data.token);
        localStorage.setItem("email", response.data.email);

        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error) => {
        responseError(error);
      });
  };

  return (
    <>
      <Header />
      <Toast
        message={feedback.message}
        severinty={feedback.severinty}
        open={toastOpen}
        handleClose={handleCloseToast}
      />
      <Box
        component="main"
        sx={{
          backgroundColor: "gray",
          pt: 5,
          pb: 5,
        }}
      >
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <Paper sx={{ mt: 3, mb: 3, width: lg ? "100%" : "50%" }}>
            <Box sx={{ height: "auto", padding: 5 }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold", color: "primary.main", mb: 3 }}
              >
                ACCREDITED REAL ESTATE SALESPERSONS <br /> OF THE PHILIPPINES
                INC.
              </Typography>
              {!verify && !success && (
                <Box component="form" onSubmit={handleSubmitLogin}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      mb: 3,
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Member Login
                  </Typography>
                  <Box
                    sx={{
                      backgroundImage: `url(${LogoMonogram})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#000",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        height: "auto",
                      }}
                    >
                      <FormControl fullWidth error sx={{ mb: 3 }}>
                        <TextField
                          label="Email"
                          name="email"
                          variant="filled"
                          size="small"
                          type="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                        <FormHelperText>{errors.email}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth error sx={{ mb: 3 }}>
                        <TextField
                          label="Password"
                          name="password"
                          variant="filled"
                          size="small"
                          type="password"
                          value={inputs.password}
                          onChange={handleChange}
                        />
                        <FormHelperText>{errors.password}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth>
                        {!submitting && (
                          <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="primary"
                          >
                            SUBMIT
                          </Button>
                        )}
                        {submitting && (
                          <LoadingButton loading variant="contained">
                            SUBMITTING ...
                          </LoadingButton>
                        )}
                      </FormControl>
                      <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                          <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            color="danger"
                            onClick={handleVerify}
                          >
                            Create user account
                          </Button>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>{" "}
                </Box>
              )}
              {verify && !verifying && !success && (
                <Box component="form" onSubmit={handleSubmitUserVerify}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Create user account
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      mb: 3,
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Verify your email address
                  </Typography>
                  <Box
                    sx={{
                      backgroundImage: `url(${LogoMonogram})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#000",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        height: "auto",
                      }}
                    >
                      <FormControl fullWidth error sx={{ mb: 3 }}>
                        <TextField
                          label="Your registered email address"
                          name="email"
                          variant="filled"
                          size="small"
                          type="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                        <FormHelperText>{errors.email}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth>
                        {!submitting && (
                          <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="primary"
                          >
                            Send verification code
                          </Button>
                        )}
                        {submitting && (
                          <LoadingButton loading variant="contained">
                            Sending ...
                          </LoadingButton>
                        )}
                      </FormControl>
                      <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                          <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            color="danger"
                            onClick={handleReset}
                          >
                            Back
                          </Button>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>{" "}
                </Box>
              )}
              {verify && verifying && !success && (
                <Box component="form" onSubmit={handleSubmitCode}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Create user account
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Please submit the verification code
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{
                      color: "gray",
                      mb: 3,
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    <small>
                      If you didn't received the verification code directly,{" "}
                      <br />
                      please check your spam messages tab.
                    </small>
                  </Typography>
                  <Box
                    sx={{
                      backgroundImage: `url(${LogoMonogram})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#000",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        height: "auto",
                      }}
                    >
                      <FormControl fullWidth error sx={{ mb: 3 }}>
                        <TextField
                          label="Enter the verification code"
                          name="verification_code"
                          variant="filled"
                          size="small"
                          type="text"
                          value={inputs.verification_code}
                          onChange={handleChange}
                        />
                        <FormHelperText>
                          {errors.verification_code}
                        </FormHelperText>
                      </FormControl>
                      <FormControl fullWidth>
                        {!submitting && (
                          <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="primary"
                          >
                            Submit verification code
                          </Button>
                        )}
                        {submitting && (
                          <LoadingButton loading variant="contained">
                            Submitting ...
                          </LoadingButton>
                        )}
                      </FormControl>
                      <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                          <Button
                            variant="outlined"
                            type="button"
                            size="large"
                            color="danger"
                            onClick={handleReset}
                          >
                            Back
                          </Button>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>{" "}
                </Box>
              )}
              {success && (
                <Box component="form" onSubmit={handleSubmitCode}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Email verified!
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      mb: 3,
                      textAlign: lg ? "center" : "left",
                    }}
                  >
                    Your user account password was sent to your email. <br />
                    <small>{inputs.email}</small>
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <FormControl fullWidth>
                      <Button
                        variant="contained"
                        type="button"
                        size="large"
                        color="primary"
                        onClick={handleReset}
                      >
                        Continue
                      </Button>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default MemberLogin;
