import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FooterLogo from "../imgs/acres_white.png";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const Footer = (props) => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Box sx={{ width: "100%", backgroundColor: "dark.main" }}>
        <Container sx={{ pt: 5 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src={FooterLogo} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Grid sx={{ marginTop: -1 }} container>
                <Grid item xs={12} md={12} lg={12}>
                  <Box sx={{ mt: 6, pl: lg ? 0 : 16, pr: lg ? 0 : 16 }}>
                    <List sx={{ display: "block", color: "#fff" }}>
                      <ListItem
                        sx={{
                          textAlign: "center",
                          p: 0,
                          textTransform: "uppercase",
                        }}
                      >
                        <ListItemText>
                          <Link
                            href="/"
                            sx={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: lg ? 12 : 15,
                            }}
                          >
                            {" "}
                            Home
                          </Link>
                        </ListItemText>
                        <ListItemText>
                          <Link
                            href="/who-we-are"
                            sx={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: lg ? 12 : 15,
                            }}
                          >
                            {" "}
                            Who We Are
                          </Link>
                        </ListItemText>
                        <ListItemText>
                          <Link
                            href="/members"
                            sx={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: lg ? 12 : 15,
                            }}
                          >
                            {" "}
                            Members
                          </Link>
                        </ListItemText>
                        <ListItemText>
                          <Link
                            href="/join-now"
                            sx={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: lg ? 12 : 15,
                            }}
                          >
                            {" "}
                            Join Now
                          </Link>
                        </ListItemText>
                        <ListItemText>
                          <Link
                            href="#"
                            sx={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: lg ? 12 : 15,
                            }}
                          >
                            {" "}
                            Privacy Policy
                          </Link>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                  <Box
                    container
                    sx={{ mt: 3, pl: lg ? 15 : 50, pr: lg ? 15 : 50 }}
                  >
                    <List sx={{ display: "block", color: "#fff" }}>
                      <ListItem sx={{ textAlign: "center" }}>
                        <ListItemText>
                          <Link
                            href="https://www.facebook.com/acresph"
                            sx={{ textDecoration: "none", color: "#fff" }}
                          >
                            {" "}
                            <FacebookIcon sx={{ fontSize: lg ? 30 : 40 }} />
                          </Link>
                        </ListItemText>
                        <ListItemText>
                          <TwitterIcon sx={{ fontSize: lg ? 30 : 40 }} />
                        </ListItemText>
                        <ListItemText>
                          <InstagramIcon sx={{ fontSize: lg ? 30 : 40 }} />
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container sx={{ p: 3, color: "#fff", textAlign: "center" }}>
          &copy; ACRES | PH 2022 All Rights Reserved
        </Container>
      </Box>
    </>
  );
};

export default Footer;
