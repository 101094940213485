import AxiosInstance from "./AxiosInstance";
import { useState, useEffect } from "react";

// check for session user token on local storage
const useAuthenticated = function () {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("authToken") !== null) {
      AxiosInstance.get("/authenticate").then((response) => {
        response.data.status == 200 ? setAuth(true) : setAuth(false);
      });
    } else {
      setAuth(false);
    }
  }, []);

  return auth;
};

export default useAuthenticated;
