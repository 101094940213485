import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Banner from "../../imgs/members_background.jpg";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import AxiosInstance from "../config/AxiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../imgs/favicon.ico";
import Header from "../Header";
import Footer from "../Footer";
import CryptoJS from "crypto-js";
import unserialize from "locutus/php/var/unserialize";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid #2d50d3`,
}));

const Members = (props) => {
  const theme = useTheme();
  const [members, setMembers] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [currentCities, setCurrentCities] = useState(null);
  const [searchMembers, setSearchMembers] = useState({
    selectedMembers: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    selectedMember: null,
    selectedMemberId: 0,
  });
  const [selected, setSelected] = useState({
    selectedCities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    selectedCity: null,
    selectedCityId: 0,
  });
  let lg = useMediaQuery(theme.breakpoints.up("lg"));

  const setCurrentMembers = (data) => {
    const currMembers = data.map((member) => ({
      id: member.id,
      firstname: member.firstname,
      middlename: member.middlename,
      lastname: member.lastname,
      image: member.requirements[0]?.image_path,
      prc_resolution_number: member.requirements[0]?.prc_resolution_number,
    }));

    return currMembers;
  };

  const handleChangeCities = async (e, v) => {
    setLoaded(false);

    if (v?.id) {
      set("selectedCity", v);
      set("selectedCityId", v.id);

      const response = await AxiosInstance.get("/members/city", {
        params: { city_id: v.id },
      });
      const data = response.data;

      const currMembers = setCurrentMembers(data);

      setMembers(currMembers);
      setLoaded(true);
    } else {
      set("selectedCity", null);
      set("selectedCityId", 0);
      fetchMembers();
    }
  };

  const set = (key, value) => {
    setSelected((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setSelectedSearchMembers = (key, value) => {
    setSearchMembers((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  /*const handleChangeForMember = (e) => {
    const keyword = e.target.value.toUpperCase();
    const filtered = members.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val === "string" && val.includes(keyword)
      )
    );

    let filteredNew = e.target.value.length > 0 ? filtered : null;

    setSearchedMembers(filteredNew);
  };*/

  const handleChangeSearchedMember = async (e, v) => {
    setLoaded(false);

    if (v?.id) {
      setSelectedSearchMembers("selectedMember", v);
      setSelectedSearchMembers("selectedMemberId", v.id);

      const response = await AxiosInstance.get("/member", {
        params: { member_id: v.id },
      });
      const data = response.data;

      const currMembers = await setCurrentMembers(decrypt(data));

      setMembers(currMembers);
      setLoaded(true);
    } else {
      setSelectedSearchMembers("selectedMember", null);
      setSelectedSearchMembers("selectedMemberId", 0);
      fetchMembers();
    }
  };

  const getUniqueAddressName = (data) => {
    const uniqeNames = [];

    const unique = data.filter((element) => {
      const isDuplicate = uniqeNames.includes(element.label);

      if (!isDuplicate) {
        uniqeNames.push(element.label);

        return true;
      }

      return false;
    });

    return unique;
  };

  const decrypt = (data) => {
    const encrypted = JSON.parse(atob(data.content));
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCESS_KEY);

    const decrypted = CryptoJS.AES.decrypt(encrypted.value, key, {
      iv: iv,
    }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(unserialize(decrypted));
  };

  const fetchMembers = async () => {
    const response = await AxiosInstance.get("/members");
    const data = response.data;

    const currMembers = await setCurrentMembers(decrypt(data));

    setMembers(currMembers);
    setLoaded(true);
  };

  useEffect(() => {
    const fetchMembersAll = async () => {
      const response = await AxiosInstance.get("/members/all");
      const data = decrypt(response.data);

      const selectedMembers = data.map((member) => ({
        id: member.id,
        label: (
          member.lastname +
          " " +
          member.firstname +
          ", " +
          member.middlename[0] +
          ". "
        ).toUpperCase(),
      }));

      setSelectedSearchMembers("selectedMembers", selectedMembers);
    };

    const fetchCities = async () => {
      const response = await AxiosInstance.get("/membership/cities");

      const data = response.data.map((province) => {
        return province.cities.map((city, index) => {
          return {
            id: city.id,
            label: city.name + ", " + province.name,
          };
        });
      });

      let cities = [];

      for (let x = 0; x < data.length; x++) {
        data[x].map((city) => {
          cities.push({
            id: city.id,
            label: city.label,
          });
        });
      }

      const uniqueCities = getUniqueAddressName(cities);

      set("selectedCities", uniqueCities);
    };

    const fetchCurrentCities = async () => {
      const response = await AxiosInstance.get("/cities/with-members");

      setCurrentCities(response.data);
    };

    fetchCities();
    //fetchCurrentCities();
    fetchMembers();
    fetchMembersAll();
  }, []);

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <Box
          sx={{
            color: "#000",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            height: "auto",
            pb: 10,
          }}
        >
          <Box sx={{ p: 5 }}>
            <Typography
              variant={lg ? "h3" : "h4"}
              component="div"
              sx={{
                color: "primary.main",
                textAlign: "center",
                mb: 3,
                mt: 3,
                fontWeight: "bold",
              }}
            >
              OUR MEMBERS
            </Typography>
            <Box sx={{ paddingRight: lg ? 20 : 0, paddingLeft: lg ? 20 : 0 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} xs={12} md={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          sx={{ mt: 3 }}
                          options={selected.selectedCities}
                          value={selected.selectedCity}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              variant="filled"
                              name="membership_city"
                              type="text"
                            />
                          )}
                          onChange={(event, value) => {
                            handleChangeCities(event, value);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.id === value.id
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={9} xs={12} md={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          sx={{ mt: 3 }}
                          options={searchMembers.selectedMembers}
                          value={searchMembers.selectedMember}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select a member..."
                              variant="filled"
                              name="member"
                              type="text"
                            />
                          )}
                          onChange={(event, value) => {
                            handleChangeSearchedMember(event, value);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.id === value.id
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              {members !== null &&
                loaded &&
                members.map((member) => (
                  <Grid item lg={3} xs={6} md={12} key={member.id}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} xs={12} md={12}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {/* <Avatar
                            key={member.id}
                            alt={member.firstname}
                            src={`https://acres.ph/backend/public/uploads/${member.image}`}
                            sx={{
                              width: lg ? 150 : 70,
                              height: lg ? 210 : 70,
                              fontSize: lg ? 40 : 20,
                              borderTop: "3px solid #2d50d3",
                              borderRight: "3px solid #db2a2a",
                              borderLeft: "3px solid #db2a2a",
                              borderBottom: "3px solid #f7cb5e",
                            }}
                            variant="rounded"
                          /> */}
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <SmallAvatar alt="Member" src={Logo} />
                            }
                          >
                            <Avatar
                              alt={member.firstname}
                              sx={{
                                width: lg ? 150 : 100,
                                height: lg ? 150 : 100,
                                fontSize: lg ? 40 : 20,
                                borderTop: "4px solid #2d50d3",
                                borderRight: "4px solid #db2a2a",
                                borderLeft: "4px solid #db2a2a",
                                borderBottom: "4px solid #f7cb5e",
                                paddingTop: 0,
                              }}
                              src={`https://acres.ph/backend/public/uploads/${member.image}`}
                            />
                          </Badge>
                        </Box>
                      </Grid>
                      <Grid item lg={12} xs={12} md={12}>
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              fontSize: lg ? 15 : 15,
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            {member.firstname} {member.middlename[0]}
                            {". "}
                            {member.lastname}
                          </Typography>
                          <Typography
                            variant="small"
                            component="div"
                            sx={{ fontSize: lg ? 12 : 12 }}
                          >
                            PRC RESO NO: {member.prc_resolution_number}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {members?.length === 0 && loaded && (
              <Box sx={{ m: 1, textAlign: "center" }}>
                <Typography variant={lg ? "h4" : "h5"} component="div">
                  No members found.
                </Typography>
              </Box>
            )}
            {!loaded && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Members;
