import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Banner from "../../imgs/banner.jpg";
import Button from "@mui/material/Button";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const Home = (props) => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.up("lg"));
  const loggedIn = localStorage.getItem("authToken") !== null;

  return (
    <>
      <Header />
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <Box container sx={{ p: lg ? 15 : 7 }}>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Welcome to ACRES
              </Typography>
            </Box>
            <Typography
              variant={lg ? "h3" : "h4"}
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Are you a Licensed
            </Typography>
            <Typography
              variant={lg ? "h3" : "h4"}
              component="div"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              Real Estate Salesperson?
            </Typography>
            <Box sx={{ pt: 2 }}>
              <Grid container>
                <Grid item sx={{ mr: 2 }}>
                  {!loggedIn && (
                    <Link
                      to={`/join-now`}
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      <Button
                        color="primary"
                        variant={"contained"}
                        disableElevation
                        sx={{ borderRadius: 0 }}
                      >
                        Join Now
                      </Button>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              backgroundImage: `url(${Banner})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: lg ? "69vh" : "50vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <Box
                sx={{
                  mt: lg ? 15 : 6,
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                  width: "100%",
                  paddingTop: lg ? 10 : 5,
                  paddingBottom: 5,
                  pl: 6,
                }}
              >
                <Typography
                  variant={lg ? `h4` : `h5`}
                  component="div"
                  sx={{ color: "dark.main", fontWeight: "bold" }}
                >
                  Be a part of the{" "}
                  <Typography
                    variant={"span"}
                    component="span"
                    sx={{ color: "primary.main" }}
                  >
                    biggest real estate
                  </Typography>{" "}
                  salespersons association of the Philippines
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Link
                    to={`/who-we-are`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <Button
                      color="danger"
                      variant={"contained"}
                      disableElevation
                      sx={{ borderRadius: 0 }}
                    >
                      Learn More
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        container
        sx={{
          pt: lg ? 8 : 7,
          pl: lg ? 15 : 7,
          pb: lg ? 15 : 7,
          backgroundColor: "rgba(67, 171, 255, 0.1)",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography
              variant={lg ? "h3" : "h4"}
              component="div"
              sx={{ fontWeight: "bold", color: "primary.main", mb: 2 }}
            >
              Who We Are
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Typography variant="p" component="div">
              ACRES Philippines is the country's first and largest organization
              of accredited real estate salespersons <br />
              Seeking to work harmoniously and collaboratively with
              professionals in the real estate service, regulatory <br />
              agencies, real estate organizations, property companies, policy
              makers, advocacy groups.
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Link
                to={`/members`}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button
                  color="primary"
                  variant={"contained"}
                  disableElevation
                  sx={{ borderRadius: 0 }}
                >
                  Our Members
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Home;
